// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Rozha+One&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Merriweather", serif;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #357088;
}

#content,
#content-main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

#content {
  justify-content: space-between;
  width: 100%;
}

#content-main {
  margin-top: 90px;
  margin-bottom: 50px;
  background-color: #357088;
}`, "",{"version":3,"sources":["webpack://./src/styles/main.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAIA;EACE,SAAA;EACA,sBAAA;EACA,kCAAA;AADF;;AAIA;EACE,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,yBCPgB;ADMlB;;AAIA;;EAEE,aAAA;EACA,sBAAA;EACA,YAAA;AADF;;AAIA;EACE,8BAAA;EACA,WAAA;AADF;;AAIA;EACE,gBAAA;EACA,mBAAA;EACA,yBCzBgB;ADwBlB","sourcesContent":["@import \"./colors.scss\";\n@import url(\"https://fonts.googleapis.com/css2?family=Rozha+One&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap\");\n\n* {\n  margin: 0;\n  box-sizing: border-box;\n  font-family: \"Merriweather\", serif;\n}\n\nbody {\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  background-color: $blue-dark-color;\n}\n\n#content,\n#content-main {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n}\n\n#content {\n  justify-content: space-between;\n  width: 100%;\n}\n\n#content-main {\n  margin-top: 90px;\n  margin-bottom: 50px;\n  background-color: $blue-dark-color;\n}\n","$background-dark: #2a2a2a;\n$background-darkish: rgb(58, 58, 58);\n$background-dark-90: rgba(42, 42, 42, 0.9);\n$background-dark-80: rgba(42, 42, 42, 0.8);\n$background-light: #f5f1de;\n$background-light-2: #cccccc;\n$gold-color: #ebd693;\n$gold-dark-color: #a3771f;\n$blue-color: #4fa9cc;\n$blue-dark-color: #357088;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
