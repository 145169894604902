// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  position: fixed;
  bottom: 0;
  min-height: 50px;
  width: 100%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2a2a2a;
  border-top: 1px solid #ebd693;
  padding-inline: 10px;
  /*   img {
    max-height: 25px;
  }

  .seagull-img {
    max-height: 10px;
  } */
}
footer a {
  color: #ebd693;
  text-decoration: none;
  font-size: 0.8rem;
  color: #f5f1de;
}`, "",{"version":3,"sources":["webpack://./src/styles/footer.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,SAAA;EACA,gBAAA;EAEA,WAAA;EACA,cAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EAEA,yBCbgB;EDchB,6BAAA;EAEA,oBAAA;EASA;;;;;;KAAA;AANF;AADE;EACE,cCbS;EDcT,qBAAA;EACA,iBAAA;EACA,cClBe;ADqBnB","sourcesContent":["@import \"./colors.scss\";\n\nfooter {\n  position: fixed;\n  bottom: 0;\n  min-height: 50px;\n\n  width: 100%;\n  flex-shrink: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  background-color: $background-dark;\n  border-top: 1px solid $gold-color;\n\n  padding-inline: 10px;\n\n  a {\n    color: $gold-color;\n    text-decoration: none;\n    font-size: 0.8rem;\n    color: $background-light;\n  }\n\n  /*   img {\n    max-height: 25px;\n  }\n\n  .seagull-img {\n    max-height: 10px;\n  } */\n}\n","$background-dark: #2a2a2a;\n$background-darkish: rgb(58, 58, 58);\n$background-dark-90: rgba(42, 42, 42, 0.9);\n$background-dark-80: rgba(42, 42, 42, 0.8);\n$background-light: #f5f1de;\n$background-light-2: #cccccc;\n$gold-color: #ebd693;\n$gold-dark-color: #a3771f;\n$blue-color: #4fa9cc;\n$blue-dark-color: #357088;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
